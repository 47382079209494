@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700;800;900&display=swap');


.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}


.video-container video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.homepage-content {
  background-color: rgba(255, 255, 255, 0.668);
  margin-top: 60px;
  padding: 30px;
 
}

.add-honey-hole {
  padding: 10px;
  margin:auto;
}

.add-honey-hole:hover {
  color: white;
  background-color: coral;
  border: 1px solid white;
}

h1 {
  font-size: clamp(3.3rem, 6vw, 5.3rem) !important;
  font-family: 'Chivo Mono', monospace;
  line-height: 1.1em;
  font-weight: 800;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.422);
}

h2.card-title{
  font-size: clamp(3rem, 5vw, 5.3rem);
  color: coral;
  font-family: 'Roboto Slab', serif; 
  /* font-size: 80px !important; */
  margin-bottom: 3rem;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.422);
}

.heroP{
  color: black;
  font-family: 'Chivo Mono', monospace;
  font-size: clamp(1rem , 2vw, 1.3rem);
}

.card {
  background-color: rgba(246, 235, 235, 0.641) !important;
}


.card-body{  
  width: 100%;
}

/* .bottom-half{
  background-color: rgba(14, 2, 2, 0.345) !important;
  padding: 10px !important;
  margin: 0 !important; 
} */

.hole-card .bottom-half {
  flex: 1 !important;
}

.hole-card {
  font-family: 'Chivo Mono', monospace;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: coral;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: all 1.0s ease-in-out;
}

.hole-card figure {
  overflow: hidden;
}

.hole-card:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 2px #5a5a5a;
  transition: transform 0.7s ease-out;
}

.hole-card img{
  width: 100%;
  transition: transform 1.1s ease-out;
  cursor: pointer;
}

.hole-card img:hover{
  transform: scale(1.15);
}

.modal-header{
  text-align: center;
}


h2.location{
  font-size: clamp(1.3rem, 4vw, 1.4rem);
  font-weight: 800;
  text-align: center;
}

.species {
  font-size: clamp(0.8rem, 4vw, 0.9rem);
  margin-bottom: 5px;
}
.size {
  font-size: clamp(0.8rem, 4vw, 0.9rem);
  margin-bottom: 5px;
}


.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  font-family: 'Chivo Mono', monospace;
}

.form-field input {
  font-size: 1rem;
  font-family: 'Chivo Mono', monospace;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.three-things-container p {
  font-size: clamp(1.2rem, 4vw, 1.45rem);
  font-family: 'Chivo Mono', monospace;
}
.flip-card {
  background-color: transparent;
  font-family: 'Chivo Mono', monospace;
  width: 400px;
  height: 500px;
  margin: 0 auto;
  perspective: 1000px;
  font-family: sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
  font-family: 'Chivo Mono', monospace;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid coral;
  border-radius: 1rem;
}

.flip-card-front {
  background-color: rgba(255, 255, 255, 0.612);
  color: black;
}

.flip-card-back {
  background: linear-gradient(120deg, rgb(255, 174, 145) 30%, coral 88%,
     bisque 40%, rgb(255, 185, 160) 78%);
  color: white;
  transform: rotateY(180deg);
  text-align: left;
}

.qf-img{
  width: 55%;
  margin: auto;
}

h1.header{
  font-family: 'Roboto Slab', serif;
  font-size: clamp(20rem, 4vw, 30rem);
  text-align: center;
  color: coral;
}

.add-honey-hole {
  font-family: 'Chivo Mono', monospace;
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: coral;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid coral;
}

.add-honey-hole:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid coral;
  box-shadow: 4px 5px 17px -4px coral;
}

.add-honey-hole::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: coral;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.add-honey-hole:hover::before {
  width: 250%;
}

.delete-button{
  font-family: 'Chivo Mono', monospace;
  /* padding: 1em 2em; */
  border: none;
  border-radius: 5px;
  font-weight:100;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: red;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid red;
}

.delete-button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid red;
  box-shadow: 4px 5px 17px -4px red;
}

.delete-button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: red;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.delete-button:hover::before {
  width: 250%;
}


.create-button{
  text-align: center;
  font-family: 'Chivo Mono', monospace;
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: coral;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid coral;
}

.create-button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid coral;
  box-shadow: 4px 5px 17px -4px coral;
}

.create-button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: coral;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.create-button:hover::before {
  width: 250%;
}

 .footer{
  color:coral;
 }
 




















